import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CourseType } from "./interfaces";
import { getCourses } from "./thunks";

const initialState: { courses: CourseType[]; loading: boolean } = {
  courses: [],
  loading: false,
};

export const coursesStore = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCourses.fulfilled,
        (state, action: PayloadAction<CourseType[]>) => {
          state.courses = action.payload;
          state.loading = false;
        }
      )
      .addCase(getCourses.rejected, (state) => {
        state.courses = [];
        state.loading = false;
      });
  },
});

export default coursesStore.reducer;
