import * as React from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  DataManagmentModal,
  Footer,
  HeadingContainer,
  Navigation,
} from "../components";
import { footerUrls } from "../assets/data";
import { theme } from "../theme";
import { HeadingTextPositionType, UserRole } from "../assets/enums";
import { SearchBar } from "../ui";
import { ManagmentTable } from "../components/Table";
import { getComparator, stableSort } from "../assets/utils";
import { useAppDispatch, useAppSelector } from "../hooks";
import { UserType } from "../assets/interfaces";
import { getUsers } from "../stores";

export const ManagmentPage = () => {
  const dispatch = useAppDispatch();
  const token = sessionStorage.getItem("token")!;

  if (
    !sessionStorage.getItem("token") ||
    sessionStorage.getItem("token") === "undefined" ||
    sessionStorage.getItem("token") === undefined
  )
    return <Navigate to="/" />;

  React.useEffect(() => {
    dispatch(getUsers(token));
  }, []);
  const { users } = useAppSelector((state) => state.users);

  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [selectedUser, setSelectedUser] = React.useState<UserType | null>(null);
  const [displayStatus, setDisplayStatus] = React.useState<boolean>(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const setUser = (user: UserType) => {
    setSelectedUser(user);
    setDisplayStatus(!displayStatus);
  };

  return (
    <ThemeProvider theme={theme}>
      <Navigation />
      <HeadingContainer
        label="PANEL ADMINISTRACYJNY"
        position={HeadingTextPositionType.Center}
      />
      <SearchBar handleSearch={handleSearch} searchQuery={searchQuery} />
      {selectedUser !== null && (
        <DataManagmentModal
          displayStatus={displayStatus}
          setDisplay={setDisplayStatus}
          user={selectedUser}
        />
      )}
      <ManagmentTable
        data={stableSort(users, getComparator("asc", "name"))}
        setUser={setUser}
        searchQuery={searchQuery}
      />
      <Footer urls={footerUrls} />
    </ThemeProvider>
  );
};
