import * as React from "react";
import { Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme";
import { Copyright } from "../components/Copyright";
import "../assets/styles/styles.css";
import "../assets/styles/animations.css";
import { AlertPosition, AlertType, CopyrightType } from "../assets/enums";
import { HeadingLogo } from "../components";
import ActionAlerts from "../ui/Alerts";
import { useAppDispatch } from "../hooks";
import { SignIn } from "../stores";

export const SignInPage = () => {
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  )
    return <Navigate to="/panel" />;

  const [displayErrorAlert, setIsDisplayAlert] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const showAlert = () => {
    setIsDisplayAlert(true);
    setTimeout(() => {
      setIsDisplayAlert(false);
    }, 5000);
  };

  const closeAlert = () => {
    setIsDisplayAlert(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      email: data.get("email") as string,
      password: data.get("password") as string,
    };

    try {
      const payload = await dispatch(SignIn(formData));
      if (payload.meta.requestStatus === "rejected") showAlert();
    } catch (error: any) {
      showAlert();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {displayErrorAlert && (
        <ActionAlerts
          alertDisplayStatus={true}
          alertPosition={AlertPosition.Fixed}
          alertType={AlertType.Error}
          alertTitle="Błąd logowania"
          alertText="Wprowadzone dane logowania są niepoprawne lub konto nie istnieje. Spróbuj ponownie."
          setStateOfAlert={closeAlert}
        />
      )}
      <Container className="fadeIn" component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "100vh",
            justifyContent: "center",
          }}
        >
          <HeadingLogo />

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Wpisz swój e-mail"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Wpisz swoje hasło"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mt: 3, mb: 3 }}
                  className="hover-button-color"
                >
                  Zaloguj się
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                Nie posiadasz konta?
                <Link href="/rejestracja" variant="body2">
                  {" Załóż je!"}
                </Link>
              </Grid>
              <Grid item>
                Nie pamiętasz hasła?
                <Link href="/reset-hasla" variant="body2">
                  {" Resetuj hasło"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright type={CopyrightType.Form} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};
