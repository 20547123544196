import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CourseType } from "./interfaces";
import { RootState } from "../store";

export const getCourses = createAsyncThunk<
  CourseType[],
  { userId: string; authToken: string },
  { rejectValue: string; state: RootState }
>(
  "courses/getCourses",
  async ({ userId, authToken }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const response = await axios.get<CourseType[]>(
        `${process.env.REACT_APP_API_URL}/courses?userId=${userId}`,
        { headers: { "auth-token": authToken } }
      );
      return response.data;
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message ?? "Błąd pobierania kursów";
      return rejectWithValue(errorMessage);
    }
  }
);
