import { createSlice } from "@reduxjs/toolkit";
import { redirect } from "react-router-dom";
import { AuthType } from "./interfaces";
import { getMe, SignIn, SignOut } from "./thunks";

const initialState: AuthType = {
  loading: false,
  token: null,
  user: {
    id: null,
    names: null,
    email: null,
    company: null,
    nip: null,
    role: null,
  },
};

export const authStore = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(SignIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(SignIn.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        sessionStorage.setItem("token", action.payload.token!);
      })
      .addCase(SignIn.rejected, (state) => {
        state.loading = false;
        state.token = initialState.token;
        try {
          sessionStorage.removeItem("token");
          redirect("/");
        } catch (err) {
          console.log("Nie udało się usunąć tokenu z sessionStorage:", err);
        }
      })
      .addCase(getMe.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.loading = false;
        state.user = {
          id: action.payload.user.id,
          names: action.payload.user.names,
          email: action.payload.user.email,
          company: action.payload.user.company,
          nip: action.payload.user.nip,
          role: action.payload.user.role,
        };
        state.token = sessionStorage.getItem("token");
      })
      .addCase(getMe.rejected, (state) => {
        state.loading = false;
        state.user = initialState.user;
      })
      .addCase(SignOut.pending, (state) => {
        state.loading = true;
      })
      .addCase(SignOut.fulfilled, (state) => {
        state.loading = false;
        state.user = initialState.user;
        state.token = initialState.token;
        try {
          sessionStorage.removeItem("token");
        } catch (err) {
          console.log("Nie udało się usunąć tokenu z sessionStorage:", err);
        }
      })
      .addCase(SignOut.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default authStore.reducer;
