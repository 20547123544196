import * as React from "react";
import { Typography } from "@mui/material";
import { Courses } from "../assets/interfaces";

interface Props extends Courses {
  onCourseItemClick: (id: string) => void;
}

export const CourseItem = ({
  imageUrl,
  title,
  id,
  onCourseItemClick,
}: Props) => {
  const handleItemClick = () => {
    onCourseItemClick(id);
  };

  return (
    <div className="course-item fadeIn" onClick={handleItemClick}>
      <img src={imageUrl} alt={title} />
      <div className="course-item-overlay">
        <Typography className="course-item-overlay-item">{title}</Typography>
        <Typography className="course-item-overlay-item">
          Zobacz kurs
        </Typography>
      </div>
    </div>
  );
};
