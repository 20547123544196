import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { Container } from "@mui/material";
import { Copyright } from "../components";
import { CopyrightType } from "../assets/enums";
import { FooterUrls } from "../assets/interfaces";
import "../assets/styles/footer.css";

interface Footer {
  urls: FooterUrls[];
}

export const Footer = ({ urls }: Footer) => {
  return (
    <Container className="fadeIn footer-container">
      <Box className="footer-container-urls">
        {urls.map((page, index) => (
          <Link
            href={page.path}
            underline="none"
            className="hover-red-color footer-container-urls-item"
            key={index}
            target="_blank"
          >
            {page.urlName}
          </Link>
        ))}
      </Box>
      <Copyright type={CopyrightType.Normal} />
    </Container>
  );
};
