export const nipValidate = (nip: string) => {
  const nipRegExp = /^[0-9]{1,10}$/;
  if (nipRegExp.test(nip) === false) return false;

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(nip.slice(i, i + 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};
