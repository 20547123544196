import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { NavigationUrls } from "../assets/interfaces";
import "../assets/styles/styles.css";
import { SignOut } from "../stores";
import { useAppDispatch } from "../hooks";

export const DeskoptNavigation = ({ navigationLinks }: NavigationUrls) => {
  const dispatch = useAppDispatch();
  const handleSignOut = (event: any) => {
    event?.preventDefault();
    sessionStorage.removeItem("token");
    dispatch(SignOut());
  };
  return (
    <Box>
      {navigationLinks.map((page, index) =>
        index === navigationLinks.length - 1 ? (
          <span
            onClick={handleSignOut}
            className="hover-red-color deskopt-navigation"
            key={index}
          >
            Wyloguj
          </span>
        ) : (
          <Link
            href={page.path}
            underline="none"
            className="hover-red-color deskopt-navigation"
            key={index}
          >
            {page.urlName}
          </Link>
        )
      )}
    </Box>
  );
};
