import * as React from "react";
import Box from "@mui/material/Box";
import "../assets/styles/styles.css";

export const SeparatingBar = () => {
  return (
    <Box textAlign="center" sx={{ width: "100%", mt: 2, mb: 1 }}>
      <hr className="separating-bar" />
    </Box>
  );
};
