import { HeadCell } from "../interfaces";

export const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Imię i nazwisko",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "E-mail",
  },
  {
    id: "company",
    numeric: true,
    disablePadding: false,
    label: "Pracodawca",
  },
  {
    id: "createdDate",
    numeric: true,
    disablePadding: false,
    label: "Data utworzenia",
  },
  {
    id: "deletedDate",
    numeric: true,
    disablePadding: false,
    label: "Do usunięcia",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Rola",
  },
  {
    id: "more",
    numeric: true,
    disablePadding: false,
    label: "Więcej",
  },
];

export type { HeadCell };
