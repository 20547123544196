import React from "react";

export const Certificate = ({
  certificateName,
  names,
  finishDate,
  certificateRef,
  visability,
}: {
  certificateName: string;
  names: string;
  finishDate: string;
  certificateRef: React.RefObject<HTMLDivElement>;
  visability: boolean;
}) => {
  return (
    <div
      style={{
        display: visability ? "block" : "none",
      }}
      ref={certificateRef}
    >
      <h1
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "80px",
          paddingBottom: "0px",
          marginBottom: "0px",
        }}
      >
        Certyfikat
      </h1>
      <h2
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "35px",
          marginTop: "0px",
        }}
      >
        Ukończenia kursu
      </h2>
      <div
        style={{
          width: "100%",
          borderTop: "2px solid #000",
          margin: "50px 0",
          borderBottom: "2px solid #000",
          paddingTop: "30px",
          paddingBottom: "30px",
          textAlign: "center",
        }}
      >
        <h3
          style={{
            textTransform: "uppercase",
            fontSize: "20px",
            color: "#b22a2a",
          }}
        >
          {certificateName}
        </h3>
      </div>
      <h2
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "35px",
        }}
      >
        {names}
      </h2>
      <p style={{ textTransform: "uppercase", textAlign: "center" }}>
        W dniu {finishDate} ukończył powyższy kurs organizowany przez odo legis
        sp. z.o.o za pośrednictwem portalu Administracja.edu.pl
        <br />
        <br />
        Niniejszym potwierdzamy udział w kursie.
      </p>
      <div
        style={{
          display: "flex",
          maxWidth: "80%",
          margin: "80px auto 0px auto",
          justifyContent: "space-between",
        }}
        className="footer-columns"
      >
        <div
          style={{
            textAlign: "center",
            color: "#b22a2a",
            textTransform: "uppercase",
          }}
        >
          Marta Niestrawska-dróżdź
          <br />
          <span style={{ color: "#000" }}>Prezes zarządu</span>
        </div>

        <div
          style={{
            textAlign: "center",
            color: "#b22a2a",
            textTransform: "uppercase",
          }}
        >
          Dorota Wiechnik
          <br />
          <span style={{ color: "#000" }}>Wice-Prezes zarządu</span>
        </div>
      </div>
      <p
        style={{
          fontWeight: "400",
          fontSize: "10px",
          color: "rgb(159, 159, 159)",
          textTransform: "uppercase",
          textAlign: "center",
          marginTop: "100px",
        }}
      >
        Certyfikat wygenerowano automatycznie. <br />
        Chęć otrzymania orginału prosimy zgłaszać na maila biuro@odolegis.pl.
      </p>
    </div>
  );
};
