import * as React from "react";
import html2pdf from "html2pdf.js";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { Certificate } from "./Certificate";
import { useAppSelector } from "../hooks";
import { CoursesService, EmailService } from "../assets/services";
import axios from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  minHeinght: "70vh",
  maxWidth: "980px",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
};

interface Props {
  courseId: string;
  displayStatus: boolean;
  videoName: string;
  startTime: number;
  videoUrl: string | null;
  certificateStatus: boolean;
  accessStatus: boolean;
  setDisplayStatus: (status: boolean) => void;
}

export const CourseVideo = ({
  courseId,
  displayStatus,
  videoName,
  videoUrl,
  certificateStatus,
  accessStatus,
  setDisplayStatus,
}: Props) => {
  const { courses } = useAppSelector((state) => state.courses);
  const { user } = useAppSelector((state) => state.auth);

  const selectCourse = courses.filter((course) => course.id === courseId);

  const [open, setOpen] = React.useState<boolean>(displayStatus);
  const [order, setOrder] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number | null>(
    selectCourse[0].startTime
  );
  const [basic, setBasic] = React.useState<number>(0);
  const [visability, setVisability] = React.useState<boolean>(false);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const courseService = new CoursesService();

  const startVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = progress!;
    }
  };

  const token = sessionStorage.getItem("token");

  const handleClose = async () => {
    setOpen(false);
    setDisplayStatus(false);
    setOrder(false);
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/courses/accesses/update`,
        {
          startTime: 0,
        },
        {
          headers: {
            "auth-token": token,
          },
          params: { userId: user.id },
        }
      );
      setBasic(0);
    } catch (err) {
      console.log(err);
    }
  };

  const sendOrder = async () => {
    const emailService = new EmailService();
    const sendEmail = await emailService.newOrder({
      email: user.email!,
      names: user.names!,
      company: user.company!,
      course: videoName,
    });

    if (sendEmail) setOrder(true);
  };

  const handleTimeUpdate = async () => {
    if (videoRef.current) {
      if (progress === videoRef.current.duration) {
        setProgress(0);
        try {
          await axios.patch(
            `${process.env.REACT_APP_API_URL}/courses/accesses/update`,
            {
              startTime: progress,
              finishDate: new Date().toLocaleDateString(),
            },
            {
              headers: {
                "auth-token": token,
              },
              params: { userId: user.id },
            }
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        setProgress(videoRef.current.currentTime);
        setBasic(basic + 1);
        if (basic === 50) {
          setBasic(0);
          try {
            await axios.patch(
              `${process.env.REACT_APP_API_URL}/courses/accesses/update`,
              {
                startTime: progress,
                finishDate: new Date().toLocaleDateString(),
              },
              {
                headers: {
                  "auth-token": token,
                },
                params: { userId: user.id },
              }
            );
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  };

  React.useEffect(() => {
    setOpen(displayStatus);
  }, [displayStatus]);

  const certificate = React.useRef<HTMLDivElement>(null);

  const generate = async () => {
    const opt = {
      margin: 0.5,
      filename: `${videoName}-certyfikat.pdf`,
      image: { type: "jpg", quality: 0.98 },
      html2canvas: { dpi: 1200, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    setVisability(true);
    await html2pdf().from(certificate.current).set(opt).save();
    setVisability(false);
  };

  return (
    <div>
      {selectCourse[0].finishDate && (
        <Certificate
          certificateRef={certificate}
          certificateName={videoName}
          names={user.names ? user.names : "Imię i nazwisko"}
          finishDate={selectCourse[0].finishDate}
          visability={visability}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          overflow: "scroll",
          transition: "300ms",
          background: visability ? "#fff" : "none",
        }}
      >
        <Box sx={style}>
          {accessStatus && videoUrl ? (
            <video
              style={{ width: "100%" }}
              controls
              controlsList="nodownload"
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={startVideo}
              ref={videoRef}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          ) : (
            <div
              style={{
                margin: "20px 40px",
                padding: "10px 0px 40px 0px",
                borderBottom: "1px solid #ddd",
              }}
            >
              <h2>Niestety nie posiadasz dostępu.</h2>
              <p>
                Jeśli wykupiłeś dostęp, a mimo to nie możesz obejrzeć kursu -
                zgłoś nam błąd telefonicznie lub mailowo. <br /> Jeśli nie
                posiadasz dostępu - zamów go.
              </p>
              {!order ? (
                <Button onClick={sendOrder} variant="outlined">
                  Poproś o ofertę
                </Button>
              ) : (
                <h4 style={{ color: "#666" }}>
                  Wiadomość wysłana. Oczekuj na kontakt.
                </h4>
              )}
            </div>
          )}
          <Box
            style={{
              padding: accessStatus ? "30px 40px" : "20px 40px 40px 40px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: window.innerWidth > 1000 ? "row" : "column",
            }}
          >
            <Box
              style={{
                maxWidth: window.innerWidth > 1000 ? "75%" : "100%",
                width: "100%",
              }}
            >
              <h5
                style={{
                  padding: "0",
                  margin: "0",
                  textTransform: "uppercase",
                  color: "#b22a2a",
                }}
              >
                Nazwa kursu:
              </h5>
              <h4
                style={{
                  padding: "0",
                  margin: "3px 0 0 0",
                  textTransform: "uppercase",
                  lineHeight: "95%",
                }}
              >
                {videoName}
              </h4>
            </Box>
            <Box
              style={{
                width: "100%",
                maxWidth: window.innerWidth > 1000 ? "25%" : "100%",
                display: "flex",
                justifyContent:
                  window.innerWidth > 1000 ? "space-between" : "flex-start",
                paddingTop: window.innerWidth > 1000 ? "0" : "20px",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  marginRight: window.innerWidth > 1000 ? "0" : "10px",
                }}
                disabled={!certificateStatus}
                onClick={generate}
              >
                Certyfikat
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Zamknij
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
