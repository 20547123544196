import * as React from "react";
import Box from "@mui/material/Box";
import { icons } from "../assets/icons";
import "../assets/styles/styles.css";

export const FormHeadingLogo = () => {
  return (
    <Box textAlign="center" sx={{ width: "100%" }}>
      <img
        src={icons.logo}
        alt="Administracja.edu.pl - logo"
        className="form-logo"
      />
    </Box>
  );
};
