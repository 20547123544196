import logo from "./logo.svg";
import user from "./user.svg";
import hamburger from "./hamburger.svg";
import magnifer from "./magnifer.svg";
import trash from "./trash.svg";
import close from "./close.svg";

interface Icons {
  logo: string;
  user: string;
  hamburger: string;
  magnifer: string;
  trash: string;
  close: string;
}

export const icons: Icons = {
  logo,
  user,
  hamburger,
  magnifer,
  trash,
  close,
};
