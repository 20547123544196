export enum AlertType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export enum AlertPosition {
  Table = "TABLE",
  Fixed = "FIXED",
}
