import * as React from "react";
import { useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme";
import { Copyright } from "../components/Copyright";
import "../assets/styles/styles.css";
import "../assets/styles/animations.css";
import { CopyrightType } from "../assets/enums";
import { HeadingLogo } from "../components";
import { privacy, statute } from "../assets/data";
import { LegacyTemplateType } from "../assets/interfaces";

export const LegacyTemplatePage = () => {
  const { url } = useParams();

  const legacy: LegacyTemplateType[] = [statute, privacy];

  const content: LegacyTemplateType = legacy.filter(
    (content) => content.friendlyUrl === url
  )[0];

  return (
    <ThemeProvider theme={theme}>
      <Container className="fadeIn" component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "100vh",
            mt: content === undefined ? 0 : 10,
            justifyContent: "center",
          }}
        >
          <Container className="fadeIn" component="main" maxWidth="xs">
            <HeadingLogo />
          </Container>
          {content === undefined ? (
            <div className="legacy-content">
              <h2 className="not-found">404</h2>
              <p className="not-found-description">
                Strona której szukasz nie istnieje...
              </p>
            </div>
          ) : (
            <div className="legacy-content">
              <h2>{content.label}</h2>
              <div dangerouslySetInnerHTML={{ __html: content.htmlContent }} />
            </div>
          )}
          <Copyright type={CopyrightType.Form} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};
