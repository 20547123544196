import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Copyright, HeadingLogo } from "../components";
import { theme } from "../theme";
import "../assets/styles/styles.css";
import "../assets/styles/animations.css";
import { AlertPosition, AlertType, CopyrightType } from "../assets/enums";
import ActionAlerts from "../ui/Alerts";
import {
  emailValidate,
  nipValidate,
  passwordValidate,
} from "../assets/validators";
import { useAppDispatch } from "../hooks";
import { SignUp } from "../stores";
import { Navigate } from "react-router-dom";

export const SignUpPage = () => {
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  )
    return <Navigate to="/panel" />;
  const [displayErrorAlert, setIsDisplayAlert] = React.useState<boolean>(false);
  const [alertTitle, setAlertTitle] = React.useState<string>("");
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  const dispatch = useAppDispatch();

  const showAlert = () => {
    setIsDisplayAlert(!displayErrorAlert);
    setTimeout(() => {
      setIsDisplayAlert(false);
    }, 5000);
  };

  const closeAlert = () => {
    setIsDisplayAlert(false);
  };

  const setAlertData = ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setTimeout(() => {
      showAlert();
    }, 1000);
  };

  const ValidateData = (data: {
    names: string;
    email: string;
    password: string;
    company: string;
    nip: string;
    privacy: string;
    statue: string;
  }) => {
    if (!data.names && data.names.length < 6)
      return setAlertData({
        title: "Wystąpił błąd!",
        message:
          "Wygląda na to, że podane przez Ciebie imię i nazwisko są błędne. Sprawdź swoje dane.",
      });

    if (!emailValidate(data.email))
      return setAlertData({
        title: "Wystąpił błąd!",
        message:
          "Wygląda na to, że podany przez Ciebie e-mail jest błędny. Sprawdź swoje dane.",
      });

    if (!passwordValidate(data.password))
      return setAlertData({
        title: "Wystąpił błąd!",
        message:
          "Zadbaj o swoje bezpieczeństwo. Hasło powinno zawierać duże i małe litery oraz znak specjalny, a także nie być krótsze niż 8 znaków",
      });

    if (!data.company && data.company.length < 4)
      return setAlertData({
        title: "Wystąpił błąd!",
        message: "Nazwa pracodawcy jest niepoprawna. Sprawdź wprowadzone dane",
      });

    if (!nipValidate(data.nip))
      return setAlertData({
        title: "Wystąpił błąd!",
        message:
          "Wygląda na to, że podany przez Ciebie NIP jest błędny. Sprawdź wprowadzone przez siebie dane.",
      });

    if (!data.statue && data.statue !== "statute")
      return setAlertData({
        title: "Wystąpił błąd!",
        message: "Aby założyć konto musisz zaakceptować wszystkie zgody.",
      });

    if (!data.privacy && data.privacy !== "privacy")
      return setAlertData({
        title: "Wystąpił błąd!",
        message: "Aby założyć konto musisz zaakceptować wszystkie zgody.",
      });
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      names: data.get("names") as string,
      email: data.get("email") as string,
      password: data.get("password") as string,
      company: data.get("company") as string,
      nip: data.get("nip") as string,
      privacy: data.get("privacy") as string,
      statue: data.get("statue") as string,
    };

    const validateStatus = ValidateData(formData);

    if (validateStatus)
      try {
        const resultAction = await dispatch(SignUp(formData));
        const success = resultAction.payload;
        if (success) return (window.location.href = "/panel");
        return setAlertData({
          title: "Wystąpił błąd!",
          message:
            "Wystąpił problem podczas rejestracji. Spróbuj jeszcze raz. Jeśli problem będzie się powtarzał - zgłoś to do nas mailowo lub telefonicznie",
        });
      } catch (error) {
        return setAlertData({
          title: "Wystąpił błąd!",
          message:
            "Wystąpił problem podczas wykonania rejestracji. Spróbuj jeszcze raz. Jeśli problem będzie się powtarzał - zgłoś to do nas mailowo lub telefonicznie",
        });
      }
  };

  return (
    <ThemeProvider theme={theme}>
      {displayErrorAlert && (
        <ActionAlerts
          alertDisplayStatus={true}
          alertPosition={AlertPosition.Fixed}
          alertType={AlertType.Error}
          alertTitle={alertTitle}
          alertText={alertMessage}
          setStateOfAlert={closeAlert}
        />
      )}
      <Container className="fadeIn" component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "100vh",
            mt: 8,
            mb: 6,
            justifyContent: "center",
          }}
        >
          <HeadingLogo />

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="names"
                  required
                  fullWidth
                  id="names"
                  label="Wpisz imię i nazwisko"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Wpisz swój e-mail"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Wpisz swoje hasło"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="company"
                  label="Wpisz nazwę pracodawcy"
                  name="company"
                  autoComplete="company-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="nip"
                  label="Podaj NIP pracodawcy"
                  type="number"
                  name="nip"
                  autoComplete="company-nip"
                />
              </Grid>
              <Grid item xs={12} alignContent="flex-start">
                <FormControlLabel
                  name="privacy"
                  id="privacy"
                  sx={{ mt: 1 }}
                  control={<Checkbox value="privacy" color="primary" />}
                  label={
                    <span>
                      Akceptuje{" "}
                      <Link target="_blank" href="/polityka-prywatnosci">
                        politykę prywatności
                      </Link>{" "}
                      oraz{" "}
                      <Link target="_blank" href="/regulamin-serwisu">
                        regulamin serwisu.
                      </Link>
                    </span>
                  }
                />
                <FormControlLabel
                  name="statue"
                  id="statue"
                  sx={{ mt: 1 }}
                  control={<Checkbox value="statue" color="primary" />}
                  label={
                    <span>
                      wyrażam zgodę na{" "}
                      <Link target="_blank" href="/polityka-prywatnosci">
                        przetwarzanie moich danych osobowych
                      </Link>
                    </span>
                  }
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mt: 3, mb: 3 }}
                  className="hover-button-color"
                >
                  Zarejestruj się
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                Masz już konto?
                <Link href="/" variant="body2">
                  {" Zaloguj się!"}
                </Link>
              </Grid>
              <Grid item>
                Nie pamiętasz hasła?
                <Link href="/reset-hasla" variant="body2">
                  {" Resetuj hasło"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright type={CopyrightType.Form} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};
