import { FooterUrls } from "../interfaces";

export const footerUrls: FooterUrls[] = [
  {
    path: "/polityka-prywatnosci",
    urlName: "Polityka prywatności",
  },
  {
    path: "/regulamin-serwisu",
    urlName: "Regulamin serwisu",
  },
];
