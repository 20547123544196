import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthType, SignUpData, User } from "./interfaces";

export const SignIn = createAsyncThunk<
  AuthType,
  { email: string; password: string },
  { rejectValue: string }
>("auth/signinCallback", async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts/login`,
      { email, password },
      { headers: { "x-api-key": process.env.REACT_APP_API_KEY! } }
    );
    return response.data;
  } catch (err: any) {
    const errorMessage = err.response?.data?.message ?? "Błąd logowania";
    return rejectWithValue(errorMessage);
  }
});

export const UpdatePassword = createAsyncThunk<
  AuthType,
  { password: string; id: string },
  { rejectValue: string }
>("auth/signinCallback", async ({ password, id }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/accounts/update-password?id=${id}`,
      { password },
      { headers: { "x-api-key": process.env.REACT_APP_API_KEY! } }
    );
    return response.data;
  } catch (err: any) {
    const errorMessage = err.response?.data?.message ?? "Błąd logowania";
    return rejectWithValue(errorMessage);
  }
});

export const ResetPassword = createAsyncThunk<
  AuthType,
  { email: string },
  { rejectValue: string }
>("auth/signinCallback", async ({ email }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts/reset-password?email=${email}`,
      {},
      { headers: { "x-api-key": process.env.REACT_APP_API_KEY! } }
    );
    return response.data;
  } catch (err: any) {
    const errorMessage = err.response?.data?.message ?? "Błąd logowania";
    return rejectWithValue(errorMessage);
  }
});

export const getMe = createAsyncThunk<
  { user: User; token: string },
  string,
  { rejectValue: string }
>("auth/getMeCallback", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/accounts/me`,
      { headers: { "auth-token": token } }
    );
    return response.data;
  } catch (err: any) {
    return false;
  }
});

export const SignUp = createAsyncThunk<
  boolean,
  SignUpData,
  { rejectValue: string }
>("auth/signupCallback", async (data, { rejectWithValue }) => {
  const body = {
    ...data,
  };
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts/create-account`,
      body,
      { headers: { "x-api-key": process.env.REACT_APP_API_KEY! } }
    );
    return true;
  } catch (err: any) {
    return false;
  }
});

export const SignOut = createAsyncThunk("auth/signout", async (_, thunkApi) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/accounts/logout`, null, {
      headers: { "auth-token": sessionStorage.getItem("token") },
    });

    return true;
  } catch (err: any) {
    return false;
  }
});
