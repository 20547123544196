import * as React from "react";
import moment from "moment";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import { icons } from "../assets/icons";
import { headCells } from "../assets/data";
import {
  AlertPosition,
  AlertType,
  DeletedStatus,
  UserRole,
} from "../assets/enums";
import ActionAlerts from "../ui/Alerts";
import { getComparator, stableSort } from "../assets/utils";
import { Order } from "../assets/types";
import { UserType } from "../assets/interfaces";
import { useAppSelector } from "../hooks";
import axios from "axios";

interface ManagmentTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof UserType
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function ManagmentTableHead(props: ManagmentTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler =
    (property: keyof UserType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface ManagmentTableToolbarProps {
  numSelected: number;
  selectedIds: string[];
}

const ManagmentTableToolbar = (props: ManagmentTableToolbarProps) => {
  const { numSelected, selectedIds } = props;
  const [displayAlert, setDisplayAlert] = React.useState<boolean>(false);
  const [alertType, setAlertType] = React.useState<AlertType | undefined>(
    undefined
  );
  const [alertText, setAlertText] = React.useState<string>("");
  const [alertTitle, setAlertTitle] = React.useState<string>("");

  const closeAlert = () => {
    setDisplayAlert(false);
  };

  const { user } = useAppSelector((state) => state.auth);

  const deleteUsers = () => {
    if (selectedIds.includes(user.id!)) {
      setAlertType(AlertType.Error);
      setAlertTitle("Akcja zatrzymana!");
      setAlertText(
        "Twoje konto jest w zakresie do usunięcia. Nie można wykonać usunięcia siebie samego. Odznacz swoje konto."
      );
      setDisplayAlert(true);
      return setTimeout(() => {
        setDisplayAlert(false);
      }, 4000);
    }

    const token = sessionStorage.getItem("token");

    const headers = {
      "auth-token": token,
    };

    axios
      .delete(`${process.env.REACT_APP_API_URL}/accounts`, {
        headers: headers,
        data: { ids: selectedIds },
      })
      .then((response) => {
        setAlertType(AlertType.Success);
        setAlertTitle("Konta usunięte");
        setAlertText("Wybrane konta zostały usunięte.");
        setDisplayAlert(true);
        setTimeout(() => {
          setDisplayAlert(false);
        }, 4000);
      })
      .catch((error) => {
        setAlertType(AlertType.Error);
        setAlertTitle("Błąd usuwania");
        setAlertText(
          "Coś poszło nie tak w komunikacji z serwerem. Spróbuj ponownie, a jeśli błąd będzie się powtarzał - zgłoś to do opiekuna technicznego."
        );
        setDisplayAlert(true);
        setTimeout(() => {
          setDisplayAlert(false);
        }, 4000);
      });
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Zaznaczono {numSelected} pozycji
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Użytkownicy
        </Typography>
      )}
      {displayAlert && (
        <ActionAlerts
          alertDisplayStatus={true}
          alertPosition={AlertPosition.Fixed}
          alertType={alertType!}
          alertTitle={alertTitle}
          alertText={alertText}
          setStateOfAlert={closeAlert}
        />
      )}

      <Tooltip title="Usuń">
        <IconButton
          disabled={selectedIds.length > 0 ? false : true}
          onClick={deleteUsers}
        >
          <img width="25px" src={icons.trash} alt="usun" />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

interface Props {
  data: UserType[];
  searchQuery: string;
  setUser: (user: UserType) => void;
}

export const ManagmentTable = ({ data, searchQuery, setUser }: Props) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof UserType>("name");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
  const [alertDisplayStatus, setAlertDisplayStatus] = React.useState<boolean>(
    data.filter((user) => user.toDeleted === DeletedStatus.ToDeleted).length > 0
  );

  const [actuallyUsers, setActuallyUsers] = React.useState<UserType[]>([]);

  React.useEffect(() => {
    if (data.length > 0) {
      if (searchQuery.length > 0) {
        setActuallyUsers(
          data.filter(
            (user) =>
              user.company.slice(0, searchQuery.length).toUpperCase() ===
                searchQuery.toUpperCase() ||
              user.name.slice(0, searchQuery.length).toUpperCase() ===
                searchQuery.toUpperCase() ||
              user.email.slice(0, searchQuery.length).toUpperCase() ===
                searchQuery.toUpperCase() ||
              user.nip.toString().slice(0, searchQuery.length).toUpperCase() ===
                searchQuery.toUpperCase()
          )
        );
      } else setActuallyUsers(data);
    }
  }, [data]);

  const setToDeleteUserAlert = (value: boolean) => {
    setAlertDisplayStatus(value);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserType
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = actuallyUsers.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const openModal = (index: number) => {
    setActuallyUsers(stableSort(actuallyUsers, getComparator(order, orderBy)));
    setUser(actuallyUsers[page * rowsPerPage + index]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - actuallyUsers.length) : 0;

  return (
    <Box
      className="fadeIn"
      sx={{
        width: "100%",
        maxWidth: "1180px",
        margin: "10px auto",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      {alertDisplayStatus && (
        <ActionAlerts
          alertText={`Znaleziono ${
            actuallyUsers.filter(
              (user) => user.toDeleted === DeletedStatus.ToDeleted
            ).length
          } użytkowników, których należy usunąć w terminie najbliższych 30 dni. Użyj sortowania po dacie usunięcia w tabeli lub sprawdź podświetlone pozycje.`}
          alertTitle="Powiadomienie"
          alertType={AlertType.Warning}
          alertPosition={AlertPosition.Table}
          alertDisplayStatus={alertDisplayStatus}
          setStateOfAlert={setToDeleteUserAlert}
        />
      )}
      <Paper style={{ boxShadow: "none" }} sx={{ width: "100%", mb: 2 }}>
        <ManagmentTableToolbar
          numSelected={selected.length}
          selectedIds={selected.slice()}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <ManagmentTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={actuallyUsers.length}
            />
            <TableBody>
              {stableSort(actuallyUsers, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `Managment-table-checkbox-${index}`;

                  return (
                    <TableRow
                      style={{
                        background:
                          row.toDeleted === DeletedStatus.ToDeleted
                            ? "rgb(255, 244, 229)"
                            : "",
                      }}
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, row.id.toString())
                          }
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.company}</TableCell>
                      <TableCell align="right">
                        {moment(row.createdDate, "YYYY-MM-DD").format(
                          "DD.MM.YYYY"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {moment(row.deletedDate, "YYYY-MM-DD").format(
                          "DD.MM.YYYY"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.role === UserRole.Admin
                          ? "Pracownik"
                          : "Użytkownik"}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        align="right"
                        onClick={() => openModal(index)}
                      >
                        {row.more}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 50, 75, 100]}
          component="div"
          count={actuallyUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ManagmentTable;
