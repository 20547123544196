import { LegacyTemplateType } from "../interfaces";

export const privacy: LegacyTemplateType = {
  friendlyUrl: "polityka-prywatnosci",
  label: "Polityka prywatności serwisu",
  htmlContent: `
        <p>
          Wszelkie prawa do zawartości oficjalnego serwisu ODO Legis sp. z o.o. tj. www.administracja.edu.pl, www.odolegis.pl są zastrzeżone.<br><br>
    Użytkownik ma prawo do pobierania oraz drukowania całych stron lub fragmentów portalu pod warunkiem nienaruszania praw autorskich oraz praw o rejestracji
 znaków należących do ODO Legis sp. z o.o. Żadna część serwisu nie może być w całości lub części kopiowana w celach komercyjnych, transmitowana elektronicznie 
 lub w inny sposób modyfikowana, linkowana lub wykorzystana bez uprzedniej zgody.

        </p>
        <span>INFORMACJA O PRZETWARZANIU DANYCH OSOBOWYCH</span>
        <p>
          W związku z realizacją wymogów Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych „RODO”), informujemy o zasadach przetwarzania Pani/Pana danych osobowych oraz o przysługujących Pani/Panu prawach z tym związanych. <br /><br />
          Poniższe zasady stosuje się począwszy od 25 maja 2018 roku.
        </p>
        <ol>
          <li>
           Administratorem Pani/Pana danych osobowych jest ODO Legis sp. z o.o. z siedzibą w Żorach (44-240), przy ul. Bankowej 4a/13, reprezentowany przez Zarząd (dalej ODO Legis).
          </li>
          <li>
Państwa dane osobowe przetwarzane sa celu obsługi użytkownika serwisu w zakresie utrzymania jego konta oraz obsługi szkoleń wykupionych przez użytkowników.
          </li>
          <li>
           Podstawą przetwarzania danych osobowych jest art. 6 ust. 1 lit. a i b RODO.
            b RODO.
          </li>
          <li>
            Państwa dane osobowe są chronione, wobec czego administrator nie przekazuje ich podmiotom innym niż tym, upoważnionym na mocy obowiązujących przepisów oraz podmiotom, które zostały zobowiązane przez administratora do zastosowania odpowiednich standardów na postawie umów powierzenia danych osobowych (zwanych odbiorcami danych np. podmioty zapewniające obsługę w ramach dziennika elektronicznego).
          </li>
          <li>
           5.	Okres przechowywania danych osobowych jest zróżnicowany dla celów przetwarzania danych i wynika z obowiązujących przepisów prawa,lecz co do zasady dane pochodzące z konta użytkownika będą trwale usuwane po 5 latach od ostatniej aktywności użytkownika.
          </li>
          <li>
            W związku z przetwarzaniem Pani/Pana danych osobowych przysługują
            Państwu następujące uprawnienia:
            <ol>
              <li>
                prawo dostępu do danych osobowych, w tym prawo do uzyskania
                kopii tych danych;
              </li>
              <li>
                prawo do żądania sprostowania (poprawiania) danych osobowych – w
                przypadku gdy dane są nieprawidłowe lub niekompletne;
              </li>
              <li>
                prawo do żądania usunięcia danych osobowych (tzw. prawo do bycia
                zapomnianym), w przewidzianych prawem przypadkach;
              </li>
              <li>
                prawo do żądania ograniczenia przetwarzania danych osobowych – w
                przewidzianych prawem przypadkach;
              </li>
              <li>
                prawo do przenoszenia danych – tylko gdy przetwarzanie odbywa
                się na podstawie umowy lub zgody osoby, której dane są
                przetwarzane i dodatkowo odbywa się w sposób zautomatyzowany;
              </li>
              <li>
                prawo sprzeciwu wobec przetwarzania danych – w przewidzianym
                prawem przypadkach;
              </li>
              <li>
                w przypadku wyrażenia przez Państwa zgody na przetwarzanie
                danych osobowych, zawsze mają Państwo prawo, taką zgodę wycofać;
              </li>
              <li>
                Ponadto zawsze przysługuje Państwu prawo do wniesienia skargi do
                organu nadzorczego w sprawach ochrony danych osobowych tj. do
                Prezesa Urzędu Ochrony Danych Osobowych.
              </li>
            </ol>
          </li>
          <li>
            Państwa dane mogą być przetwarzane w sposób zautomatyzowany
            natomiast nie będą profilowane.
          </li>
        </ol>
        <h3>Odnośniki do innych stron</h3>
        <p>
          Strona może zawierać odnośniki do innych stron „www”, które są własnością oraz są prowadzone przez inne podmioty. Wprowadzenie takiego odnośnika na stronie ODO Legis nie wiąże się z braniem odpowiedzialności przez ODO Legis za treść docelowej strony WWW.
        </p>
        <h3>
          Jakie informacje o użytkownikach gromadzi portal
          www.administracja.edu.pl i w jaki sposób je wykorzystuje?
        </h3>
        <p>
          <u>Adresy IP</u><br /><br />
          Wykorzystujemy adresy IP użytkowników do diagnozowania problemów związanych z pracą naszego serwera, analizą naruszeń bezpieczeństwa, ankietach oraz w zarządzaniu naszą stroną WWW. Adres IP jest ponadto wykorzystywany do identyfikacji i gromadzenia danych demograficznych od osób odwiedzających witrynę (np. informacje o regionie, z którego nastąpiło połączenie). Informacje te w żaden sposób nie są łączone z danymi osobowymi subskrybentów list e-mailingowych i innych usług świadczonych przez serwis www.administracja.edu.pl.<br /><br />
          <u>Mechanizm Cookies</u><br /><br />
          Dla Państwa wygody Serwisy www.administracja.edu.pl używają plików cookies i podobnych technologii m.in. po to, by dostosować serwis do potrzeb użytkowników, i w celach statystycznych. Cookies to niewielkie pliki tekstowe, które są wysyłane przez odwiedzany przez nas serwis internetowy do naszego urządzenia (np. komputera albo telefonu).
Cookies używają też serwisy, do których się odwołujemy pokazując np. multimedia.
<br /><br />
          <u>W przeglądarce internetowej można zmienić ustawienia dotyczące cookies. Brak zmiany tych ustawień oznacza akceptację dla stosowanych tu cookies.</u><br /><br />
          Serwisy w domenie www.administracja.edu.pl stosują cookies wydajnościowe, czyli służące do zbierania informacji o sposobie korzystania ze strony, by lepiej działała, oraz funkcjonalne, czyli pozwalające „pamiętać” o ustawieniach użytkownika (np. język, rozmiar czcionki). To m.in.:<br /><br />
          <ol>
            <li>cookies google-analytics.com – statystyki dla witryn w domenie www.spmiarka.edu.pl/www .Polityka prywatności serwisu Google Analytics opisana jest tu: http://www.google.pl/intl/pl/policies/privacy/,</li>
            <li>cookies sesyjne (wygasają po zakończeniu sesji),</li>
          </ol>
          Ponadto, serwisy w domenie www.administracja.edu.pl używają również:
          <ol>
            <li>cookies generowanych podczas przełączania się do innej wersji serwisu cms, tj. wersji mobilnej oraz wersji dla słabo widzących (wai),</li>
            <li>cookies sesyjnych (wygasają po zamknięciu sesji) - używane podczas logowanie użytkownika do panelu strony,</li>
            <li>cookies utworzonych w momencie zmiany szerokości strony (jeśli witryny posiadają włączoną tą funkcjonalność),</li>
            <li>cookies ma oznaczenie, że zaakceptowano politykę prywatności ODO Legis.</li>
          </ol>
          Serwisy obce, do których czasem odsyłamy, mogą także używać cookies, które umożliwiają logowanie się, oraz służą dostarczaniu reklam odpowiadających upodobaniom i zachowaniem użytkownika. W szczególności takie cookies to:
          <ol>
            <li>w serwisie youtube.com – zawierające preferencje użytkownika, oraz liczydło kliknięć (opisane są w polityce prywatności http://www.google.pl/intl/pl/policies/privacy/)</li>
          </ol>
          <u>Poziom ochrony przed cookies ustawia się w każdej przeglądarce:</u><br /><br />
          Poziom ochrony przed cookies można ustawić w swojej przeglądarce internetowej - aż do całkowitego blokowania plików cookies. Zwiększa to poziom bezpieczeństwa i ochrony danych, ale może także uniemożliwiać niektóre funkcje, np. zalogowanie się na konto pocztowe.<br><br>
          <u>Jak zmienia się ustawienia w najbardziej popularnych wyszukiwarkach?</u><br /><br />
          Google Chrome<br><br>
          Trzeba kliknąć na menu (w prawym górnym rogu), zakładka Ustawienia > Pokaż ustawienia zaawansowane. W sekcji „Prywatność” trzeba kliknąć przycisk Ustawienia treści. W sekcji „Pliki cookie” można zmienić następujące ustawienia plików cookie: <br><br>
          Usuwanie plików cookie<br><br>
          Domyślne blokowanie plików cookie<br><br>
          Domyślne zezwalanie na pliki cookie<br><br>
          Domyślne zachowywanie plików cookie i danych stron do zamknięcia przeglądarki<br><br>
          Określanie wyjątków dla plików cookie z konkretnych witryn lub domen<br><br>
          Internet Explorer 6.0 i 7.0<br><br>
          Z menu przeglądarki (prawy górny róg): Narzędzia > Opcje Internetowe > Prywatność, przycisk Witryny. Suwakiem ustawiamy poziom, zmianę zatwierdzamy przyciskiem OK. <br><br>
          Mozilla Firefox<br><br>
          Z menu przeglądarki: Narzędzia > Opcje > Prywatność. Uaktywnić pole Program Firefox: „będzie używał ustawień użytkownika”. O ciasteczkach (cookies) decyduje zaklikanie – bądź nie – pozycji Akceptuj ciasteczka. <br><br>
          Opera<br><br>
          Z menu przeglądarki: Narzędzie > Preferencje > Zaawansowane. O ciasteczkach decyduje zaznaczenie – bądź nie – pozycji Ciasteczka. <br><br>
          Safari<br><br>
          W menu rozwijanym Safari trzeba wybrać Preferencje i kliknąć ikonę Bezpieczeństwo. Tu wybiera się poziom bezpieczeństwa w obszarze ,,Akceptuj pliki cookie".<br><br>
        </p>
        <h3>Kontakt</h3>
        <p>
          Użytkownicy, którzy mają jakieś pytania dotyczące niniejszej deklaracji ochrony prywatności, praktyk stosowanych na tej stronie, czy też sposobów utrzymywania z nią kontaktów, proszone są o wysłanie uwag na adres: biuro@odolegis.pl<br><br>
          Korzystając w jakikolwiek sposób z serwisów internetowych ODO Legis akceptujecie Państwo zasady zawarte w polityce prywatności portalu www.administracja.edu.pl, www.odolegis.pl.
        </p>
        <h3>Zmiany</h3>
        <p>W przypadku zmiany obowiązującej polityki prywatności w portalu www.administracja.edu.pl, www.odolegis.pl, wprowadzone zostaną odpowiednie modyfikacje do powyższego zapisu.</p>`,
};
