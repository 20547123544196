import * as React from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  ContactForm,
  Footer,
  HeadingContainer,
  Navigation,
} from "../components";
import { footerUrls } from "../assets/data";
import { theme } from "../theme";
import ActionAlerts from "../ui/Alerts";

import {
  AlertPosition,
  AlertType,
  HeadingTextPositionType,
} from "../assets/enums";
import "../assets/styles/styles.css";
import { FormValues } from "../assets/interfaces";
import { EmailService } from "../assets/services";
import { useAppSelector } from "../hooks";

export const ContactPage = () => {
  if (
    !sessionStorage.getItem("token") ||
    sessionStorage.getItem("token") == "undefined"
  )
    return <Navigate to="/" />;

  const [sendStatus, setSendStatus] = React.useState<boolean | undefined>(
    undefined
  );
  const [displayAlert, setIsDisplayAlert] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<{
    title: string | null;
    message: string | null;
  }>({ title: null, message: null });

  const { user } = useAppSelector((state) => state.auth);

  const sendContactEmail = async (formValues: FormValues) => {
    setFormData(formValues);
  };

  React.useEffect(() => {
    const sendEmail = async () => {
      if (formData.message && formData.title && user.id) {
        const emailService = new EmailService();
        const sendEmail = await emailService.newContact({
          email: user.email!,
          title: formData.title.toString()!,
          message: formData.message.toString(),
          names: user.names!,
        });

        setSendStatus(sendEmail);
        setIsDisplayAlert(true);

        setTimeout(
          () => {
            if (sendStatus) window.location.reload();
            setSendStatus(undefined);
            setIsDisplayAlert(false);
          },
          sendStatus ? 2000 : 5000
        );
      }
    };

    sendEmail();
  }, [formData]);

  const handleCloseAlert = () => {
    setIsDisplayAlert(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Navigation />
      <HeadingContainer
        label="Kontakt"
        position={HeadingTextPositionType.Center}
      />

      {displayAlert && (
        <ActionAlerts
          alertDisplayStatus={true}
          alertPosition={AlertPosition.Fixed}
          alertType={sendStatus ? AlertType.Success : AlertType.Error}
          alertTitle={sendStatus ? "Wiadomość wysłana!" : "Błąd wysyłania!"}
          alertText={
            sendStatus
              ? "Wiadomość wysłana poprawnie. Oczekuj na kontakt z naszej strony"
              : "Niestety coś poszło nie tak. Spróbuj ponownie, a jeśli błąd będzie się powtarzał skontaktuj się telefonicznie."
          }
          setStateOfAlert={handleCloseAlert}
        />
      )}

      <ContactForm onFormSubmit={sendContactEmail} />
      <Footer urls={footerUrls} />
    </ThemeProvider>
  );
};
