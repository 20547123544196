import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { UserRole } from "./assets/enums";
import { useAppSelector } from "./hooks";
import {
  ContactPage,
  CoursesPage,
  LegacyTemplatePage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  UpdatePasswordPage,
} from "./pages";
import { ManagmentPage } from "./pages/Managment-Page";

const requireAuth = (Component: React.ElementType) => {
  return function AuthenticatedComponent(props: any) {
    const { token } = useAppSelector((state) => state.auth);

    if (!token) {
      return <Navigate to="/" />;
    }

    return <Component {...props} />;
  };
};

export default function App() {
  const { token, user } = useAppSelector((state) => state.auth);

  //TODO: Add another function which protecting route and move routing to other file.
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="/rejestracja" element={<SignUpPage />} />
        <Route
          path="/reset-password/:resetToken"
          element={<UpdatePasswordPage />}
        />
        <Route path="/reset-hasla" element={<ResetPasswordPage />} />
        <Route path="/panel" element={<CoursesPage />} />
        <Route path="/administrator" element={<ManagmentPage />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/:url" element={<LegacyTemplatePage />} />
      </Routes>
    </Router>
  );
}
