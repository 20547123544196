import * as React from "react";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  CoursesList,
  CourseVideo,
  Footer,
  Heading,
  HeadingContainer,
  Navigation,
} from "../components";
import { footerUrls } from "../assets/data";
import { theme } from "../theme";
import { HeadingTextPositionType } from "../assets/enums";
import { useAppSelector } from "../hooks";

export const CoursesPage = () => {
  if (
    !sessionStorage.getItem("token") ||
    sessionStorage.getItem("token") == "undefined"
  )
    return <Navigate to="/" />;
  const [showAssignedCourses, setShowAssignedCourses] =
    useState<boolean>(false);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [videoModalDisplayStatus, setVideoModalDisplayStatus] =
    useState<boolean>(false);

  const { courses } = useAppSelector((state) => state.courses);

  const handleCourseItemClick = (id: string) => {
    const courseId = courses.findIndex((course) => course.id === id);
    setSelectedCourseId(courseId);
    setVideoModalDisplayStatus(!videoModalDisplayStatus);
  };

  return (
    <ThemeProvider theme={theme}>
      <Navigation />
      <Heading />
      <HeadingContainer
        label="Lista kursów"
        showAssignedCourses={showAssignedCourses}
        setShowAssignedCourses={setShowAssignedCourses}
        position={HeadingTextPositionType.Left}
      />
      {selectedCourseId !== null && (
        <CourseVideo
          courseId={courses[selectedCourseId].id}
          displayStatus={videoModalDisplayStatus}
          videoUrl={courses[selectedCourseId].videoUrl}
          videoName={courses[selectedCourseId].title}
          certificateStatus={courses[selectedCourseId].certificateStatus}
          accessStatus={courses[selectedCourseId].accessStatus}
          startTime={courses[selectedCourseId].startTime}
          setDisplayStatus={setVideoModalDisplayStatus}
        />
      )}
      <CoursesList
        onCourseItemClick={handleCourseItemClick}
        courses={courses}
        showAssignedCourses={showAssignedCourses}
      />
      <Footer urls={footerUrls} />
    </ThemeProvider>
  );
};

export default CoursesPage;
