import * as React from "react";
import Alert from "@mui/material/Alert";
import { AlertTitle, Box, Collapse, Fade, IconButton } from "@mui/material";
import { AlertPosition, AlertType } from "../assets/enums";
import { icons } from "../assets/icons";

interface Props {
  alertTitle: string;
  alertText: string;
  alertType: AlertType;
  alertPosition: AlertPosition;
  alertDisplayStatus: boolean;
  setStateOfAlert: (value: boolean) => void;
}

export default function ActionAlerts({
  alertTitle,
  alertText,
  alertType,
  alertPosition,
  alertDisplayStatus,
  setStateOfAlert,
}: Props) {
  return (
    <Fade in={true}>
      <Box
        style={
          alertPosition === AlertPosition.Table
            ? {}
            : {
                position: "fixed",
                left: "50%",
                transform: "translateX(-50%)",
                maxWidth: "1160px",
                minHeight: "100px",
                zIndex: 99,
                borderColor: "#ddd",
                bottom: "10px",
              }
        }
        sx={{ width: "100%" }}
      >
        <Collapse
          in={
            alertTitle === "Powiadomienie"
              ? sessionStorage.getItem(`${alertTitle}`) === null
              : true
          }
        >
          <Alert
            severity={alertType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  sessionStorage.setItem(`${alertTitle}`, "false");
                  setStateOfAlert(!alertDisplayStatus);
                }}
              >
                <img src={icons.close} width="30px" alt="Zamknij" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertText}
          </Alert>
        </Collapse>
      </Box>
    </Fade>
  );
}
