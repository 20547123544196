import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Alert, Button, Grid, MenuItem, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  Accesses,
  Certificates,
  Courses,
  UserType,
} from "../assets/interfaces";
// import { CoursesService } from "../assets/services";
import axios from "axios";
import { CoursesService } from "../assets/services";
import { AlertPosition, AlertType } from "../assets/enums";
import ActionAlerts from "../ui/Alerts";
import { getCourses, getMe } from "../stores";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  minHeinght: "70vh",
  maxWidth: "980px",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <p>{children}</p>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface Props {
  displayStatus: boolean;
  user: UserType;
  setDisplay: (status: boolean) => void;
}

export const DataManagmentModal = ({
  displayStatus,
  user,
  setDisplay,
}: Props) => {
  const [value, setValue] = React.useState(0);
  const [accesses, setAccesses] = React.useState<Accesses[]>([]);
  const [certificates, setCertificates] = React.useState<Courses[]>([]);
  const [startDate, setStartDate] = React.useState<any>("");
  const [endDate, setEndDate] = React.useState<any>("");
  const [displayAlert, setDisplayAlert] = React.useState<boolean>(false);
  const [alertTitle, setAlertTitle] = React.useState<string>("");
  const [alertText, setAlertText] = React.useState<string>("");
  const [alertType, setAlertType] = React.useState<AlertType>(AlertType.Error);

  const dispatch = useAppDispatch();
  const token = sessionStorage.getItem("token")!;

  React.useEffect(() => {
    dispatch(getMe(token));
    const getCoursesAsync = async () => {
      dispatch(
        getCourses({
          userId: user.id!,
          authToken: token,
        })
      );
    };
    getCoursesAsync();
  }, [dispatch, user.id, token]);

  const hideAlert = () => {
    setDisplayAlert(false);
  };

  const { courses } = useAppSelector((state) => state.courses);
  const courseService = new CoursesService();

  React.useEffect(() => {
    if (displayStatus) {
      const fetchData = async () => {
        setAccesses(await courseService.checkAccesses({ userId: user.id }));
        setCertificates(courses.filter((course) => course.finishDate !== null));
      };
      fetchData();
    }
  }, [displayStatus, user.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setDisplay(!displayStatus);
  };

  const addAccess = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const data = {
      userId: user.id,
      email: formData.get("email") as string,
      course: formData.get("course") as string,
      startDate: startDate.$d,
      endDate: endDate.$d,
    };

    const req = {
      courseId: courses.filter((course) => course.title === data.course)[0].id,
      accountId: user.id,
      startAccess: startDate.$d,
      endAccess: endDate.$d,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/courses/new-access`,
        req,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setAlertText(
        "Dostęp został dodany. Za chwilę strona zostanie odświeżona."
      );
      setAlertTitle("Powodzenie");
      setAlertType(AlertType.Success);
      setDisplayAlert(true);
      setTimeout(() => {
        setDisplayAlert(false);
        window.location.reload();
      }, 2500);
    } catch (err) {
      setAlertText(
        "Coś poszło nie tak. Spróbuj ponownie, a jeśli błąd będzie się powtarzał - zgłoś to do administratora."
      );
      setAlertTitle("Wystąpił błąd");
      setAlertType(AlertType.Error);
      setDisplayAlert(true);
      setTimeout(() => {
        setDisplayAlert(false);
      }, 4000);
    }
  };

  const changeData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const updatedUserData = {
      names: formData.get("names") as string,
      company: formData.get("company") as string,
      nip: formData.get("nip") as string,
      userType:
        (formData.get("role") as string) == "pracownik" ? "ADMIN" : "USER",
    };

    const token = sessionStorage.getItem("token");

    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/accounts/update`,
        updatedUserData,
        {
          headers: {
            "auth-token": token,
          },
          params: { id: user.id },
        }
      );
      setAlertText(
        "Zmiana danych została dokonana. Zmiany będą widoczne po odświeżeniu strony. Za chwilę strona zostanie odświeżona."
      );
      setAlertTitle("Powodzenie");
      setAlertType(AlertType.Success);
      setDisplayAlert(true);
      setTimeout(() => {
        setDisplayAlert(false);
        window.location.reload();
      }, 2500);
    } catch (err) {
      setAlertText(
        "Coś poszło nie tak. Spróbuj ponownie, a jeśli błąd będzie się powtarzał - zgłoś to do administratora."
      );
      setAlertTitle("Wystąpił błąd");
      setAlertType(AlertType.Error);
      setDisplayAlert(true);
      setTimeout(() => {
        setDisplayAlert(false);
      }, 4000);
    }
  };

  return (
    <div>
      <Modal
        open={displayStatus}
        onClose={handleClose}
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          {displayAlert && (
            <ActionAlerts
              alertText={alertText}
              alertTitle={alertTitle}
              alertType={alertType!}
              alertPosition={AlertPosition.Fixed}
              alertDisplayStatus={true}
              setStateOfAlert={hideAlert}
            />
          )}
          <Box
            style={{
              margin: "20px 20px 0px",
              padding: "10px 0px 0px 0px",
            }}
          >
            <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
              Zarządzaj użytkownikiem
            </h2>
            <Box
              style={{
                textAlign: "center",
                marginBottom: "10px",
                transform: "translateY(-5px)",
              }}
            >
              <Button variant="outlined" onClick={handleClose}>
                Zamknij
              </Button>
            </Box>

            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                mr: "20px",
                ml: "20px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="zarzadzaj"
                centered
                variant={window.innerWidth < 600 ? "scrollable" : "fullWidth"}
                scrollButtons="auto"
              >
                <Tab label="Przydziel dostęp" {...a11yProps(0)} />
                <Tab label="Aktywne dostępy" {...a11yProps(1)} />
                <Tab label="Certyfikaty" {...a11yProps(2)} />
                <Tab label="Edytuj dane" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <h3 style={{ textTransform: "uppercase", textAlign: "center" }}>
                Przydziel dostęp:
              </h3>
              <Box
                component="form"
                onSubmit={addAccess}
                noValidate
                sx={{ mt: 2 }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="E-mail użytkownika"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    style={{ width: "calc(50% - 10px)" }}
                    value={user.email}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="course"
                    name="course"
                    select
                    label="Wybierz kurs"
                    defaultValue="Wybierz kurs"
                    style={{ width: "calc(50% - 10px)" }}
                    sx={{ mt: 2 }}
                  >
                    {courses.map((course) => (
                      <MenuItem key={course.id} value={course.title}>
                        {course.title}
                      </MenuItem>
                    ))}
                  </TextField>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(newValue) => setStartDate(newValue)}
                      sx={{
                        width: "calc(50% - 10px)",
                        position: "relative",
                        bottom: "10px",
                      }}
                      label="Data rozpoczęcia"
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(newValue) => setEndDate(newValue)}
                      sx={{
                        width: "calc(50% - 10px)",
                        position: "relative",
                        bottom: "10px",
                      }}
                      label="Data zakończenia"
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </Box>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={{ mt: 3, mb: 3 }}
                      className="hover-button-color"
                    >
                      Przydziel dostęp
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <h3 style={{ textTransform: "uppercase", textAlign: "center" }}>
                Aktywne dostępy:
              </h3>
              {accesses.length > 0 ? (
                <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                  {accesses.map((access) => (
                    <li style={{ marginBottom: "15px", textAlign: "center" }}>
                      <b>{access.courseName}</b>
                      <br /> Czas trwania: od {access.startDate} do{" "}
                      {access.endDate}
                    </li>
                  ))}
                </ul>
              ) : (
                <p style={{ textAlign: "center", fontWeight: "700" }}>
                  Brak aktywnych dostępów
                </p>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <h3 style={{ textTransform: "uppercase", textAlign: "center" }}>
                Certyfikaty:
              </h3>
              {certificates.length > 0 ? (
                <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                  {certificates.map((certificate) => (
                    <li style={{ marginBottom: "15px", textAlign: "center" }}>
                      <b>{certificate.title}</b>
                      <br /> Data ukończenia: {certificate.finishDate}
                    </li>
                  ))}
                </ul>
              ) : (
                <p style={{ textAlign: "center", fontWeight: "700" }}>
                  Brak istniejących certyfikatów
                </p>
              )}
            </TabPanel>
            <TabPanel value={value} index={3}>
              <h3 style={{ textTransform: "uppercase", textAlign: "center" }}>
                Edytuj dane:
              </h3>
              <Box
                component="form"
                noValidate
                sx={{ mt: 2 }}
                onSubmit={changeData}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <TextField
                    margin="normal"
                    fullWidth
                    id="names"
                    label="Imię i nazwisko"
                    name="names"
                    autoComplete="names"
                    autoFocus
                    sx={{ mb: 0 }}
                    style={{ width: "calc(50% - 10px)" }}
                    defaultValue={user.name}
                    required
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="company"
                    label="Nazwa firmy"
                    name="company"
                    autoComplete="company"
                    autoFocus
                    style={{ width: "calc(50% - 10px)" }}
                    sx={{ mb: 0 }}
                    defaultValue={user.company}
                    required
                  />
                  <TextField
                    required
                    style={{
                      width: "calc(50% - 10px)",
                    }}
                    id="nip"
                    label="NIP firmy"
                    type="number"
                    name="nip"
                    autoComplete="company-name"
                    defaultValue={user.nip}
                    sx={{ pb: 0, mt: 0 }}
                  />
                  <TextField
                    id="role"
                    name="role"
                    select
                    label="Wybierz rolę"
                    style={{ width: "calc(50% - 10px)" }}
                    sx={{ mt: 0 }}
                  >
                    <MenuItem value="użytkownik">Użytkownik</MenuItem>
                    <MenuItem value="pracownik">Pracownik</MenuItem>
                  </TextField>
                </Box>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={{ mt: 3, mb: 3 }}
                      className="hover-button-color"
                    >
                      Zmień dane
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
