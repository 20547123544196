import * as React from "react";
import { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import { DeskoptNavigation } from "./Deskopt-Navigation";
import { MobileNavigation } from "./Mobile-Navigation";
import { icons } from "../assets/icons";
import { navigationLinks } from "../assets/data";
import { useAppSelector } from "../hooks";
import "../assets/styles/navigation.css";
import { UserRole } from "../assets/enums";

export const Navigation = () => {
  const [isOpen, setMenuStatus] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const { user } = useAppSelector((state) => state.auth);

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const navigationUrls = navigationLinks.filter((url) => {
    if (user.role === UserRole.User) return url.specialAccess === false;
    return url;
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="header" ref={headerRef}>
        <Toolbar className="fadeIn header-container">
          <Avatar src={icons.user} />
          <Typography
            component="div"
            sx={{ flexGrow: 1 }}
            className="header-namespace"
          >
            {user.names}
          </Typography>
          {window.innerWidth > 760 ? (
            <DeskoptNavigation navigationLinks={navigationUrls} />
          ) : (
            <MobileNavigation
              urls={navigationUrls}
              headerHeight={headerHeight}
              isOpen={isOpen}
            />
          )}
          {window.innerWidth < 760 && (
            <img
              src={icons.hamburger}
              style={{ width: "30px" }}
              onClick={() => setMenuStatus(!isOpen)}
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
