import * as React from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "../hooks";
import "../assets/styles/heading.css";

export const Heading = () => {
  const { user } = useAppSelector((state) => state.auth);
  return (
    <div className="heading-container">
      <div className="heading-container-image"></div>
      <div className="heading-container-content">
        {user.names && (
          <Typography
            className="fadeIn heading-container-heading-text"
            component="div"
            variant="h3"
          >
            Witaj, <span>{user.names.split(" ")[0]}!</span>
          </Typography>
        )}
        <Typography
          className="fadeIn heading-container-description-text"
          component="div"
          variant="body1"
        >
          Życzymy przyjemnej nauki przy naszych materiałach!
        </Typography>
      </div>
    </div>
  );
};
