import * as React from "react";
import { Provider } from "react-redux";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { theme } from "./theme";
import { getMe, store } from "./stores";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

if (sessionStorage.getItem("token")) {
  const token = sessionStorage.getItem("token")!;
  store.dispatch(getMe(token));
}

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);
