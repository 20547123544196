import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { FormValues } from "../assets/interfaces";
import { AlertPosition, AlertType } from "../assets/enums";
import ActionAlerts from "../ui/Alerts";

export const ContactForm = ({
  onFormSubmit,
}: {
  onFormSubmit: (formValues: FormValues) => void;
}) => {
  type ContactAlertType = "title" | "message" | null;

  const [displayErrorAlert, setIsDisplayAlert] = React.useState<boolean>(false);
  const [alertType, setAlertType] = React.useState<ContactAlertType>(null);

  const showAlert = (type: ContactAlertType) => {
    setAlertType(type);
    setIsDisplayAlert(true);
    setTimeout(() => {
      setIsDisplayAlert(false);
    }, 5000);
  };

  const closeAlert = () => {
    setIsDisplayAlert(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formValues: FormValues = {
      title: data.get("title") as string,
      message: data.get("message") as string,
    };

    if (formValues.title.length < 8) return showAlert("title");
    if (formValues.message.length < 20) return showAlert("message");

    onFormSubmit(formValues);
  };
  return (
    <Container className="fadeIn" style={{ maxWidth: "700px" }}>
      {displayErrorAlert && (
        <ActionAlerts
          alertDisplayStatus={true}
          alertPosition={AlertPosition.Fixed}
          alertType={AlertType.Error}
          alertTitle="Błąd wysyłania!"
          alertText={
            alertType === "title"
              ? "Tytuł wiadomości jest za krótki. Musi składać się z minimum 8 znaków"
              : "Wiadomość jest za krótka. Musisz przekazać nam więcej informacji!"
          }
          setStateOfAlert={closeAlert}
        />
      )}
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <TextField
          id="title"
          name="title"
          label="Temat wiadomości"
          placeholder="Wpisz temat swojej wiadomości..."
          multiline
          fullWidth
          rows={1}
          required
          sx={{ mb: 1 }}
        />
        <TextField
          id="message"
          name="message"
          label="Twoja wiadomość"
          placeholder="Wpisz treść swojej wiadomości..."
          multiline
          fullWidth
          rows={6}
          sx={{ mt: 1 }}
        />
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              type="submit"
              size="large"
              variant="contained"
              className="hover-button-color"
              sx={{ mt: 6, mb: 3 }}
            >
              Prześlij wiadomość
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
