import axios from "axios";

export class EmailService {
  constructor() {}

  async newContact({
    email,
    names,
    message,
    title,
  }: {
    email: string;
    names: string;
    message: string;
    title: string;
  }): Promise<boolean> {
    const contactData = {
      email,
      names,
      message,
      title,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/contact/new-contact`,
        contactData,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY!,
          },
        }
      );
      return true;
    } catch {
      return false;
    }
  }

  async newOrder({
    email,
    course,
    names,
    company,
  }: {
    email: string;
    course: string;
    names: string;
    company: string;
  }): Promise<boolean> {
    const orderData = {
      email,
      courseName: course,
      names,
      company,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/contact/new-order`,
        orderData,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY!,
          },
        }
      );
      return true;
    } catch {
      return false;
    }
  }
}
