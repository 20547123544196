import { configureStore } from "@reduxjs/toolkit";
import authStore from "./auth/auth-store";
import coursesStore from "./courses/courses-store";
import usersStore from "./users/users-store";

export const store = configureStore({
  reducer: {
    auth: authStore,
    courses: coursesStore,
    users: usersStore,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
