import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { CopyrightType } from "../assets/enums";
import "../assets/styles/footer.css";

interface Props {
  type: CopyrightType;
}

export const Copyright = ({ type }: Props) => {
  return type == CopyrightType.Form ? (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 8, mb: 4 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://administracja.edu.pl/">
        Administracja.edu.pl
      </Link>
      {" - "}
      {new Date().getFullYear()}
    </Typography>
  ) : (
    <Link
      className="coppyright-text"
      underline="none"
      href="https://administracja.edu.pl/"
    >
      {"Copyright © "}
      <Link
        underline="none"
        className="coppyright-text-url"
        href="https://administracja.edu.pl/"
      >
        Administracja.edu.pl
      </Link>
      {" - "}
      {new Date().getFullYear()}
    </Link>
  );
};
