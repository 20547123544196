import * as React from "react";
import { Container, FormControlLabel, Switch, Typography } from "@mui/material";
import { HeadingTextPositionType } from "../assets/enums";
import "../assets/styles/heading-container.css";
import { getMe, store } from "../stores";
import { useAppDispatch } from "../hooks";

interface Props {
  label: string;
  showAssignedCourses?: boolean;
  position: HeadingTextPositionType;
  setShowAssignedCourses?: (value: boolean) => void;
}

export const HeadingContainer = ({
  label,
  position,
  showAssignedCourses,
  setShowAssignedCourses,
}: Props) => {
  const dispatch = useAppDispatch();
  const token = sessionStorage.getItem("token")!;

  React.useEffect(() => {
    dispatch(getMe(token));
  }, []);

  return (
    <Container
      className={
        position === HeadingTextPositionType.Center
          ? "headings-container-margin headings-container fadeIn"
          : "headings-container fadeIn"
      }
    >
      <Typography variant="h5" component="div" className="heading-text">
        {label}
      </Typography>
      {position === HeadingTextPositionType.Left && setShowAssignedCourses && (
        <FormControlLabel
          style={{ marginLeft: "0" }}
          value="courses"
          control={<Switch color="primary" />}
          label={<span className="toggler-label">Posiadane kursy</span>}
          labelPlacement="start"
          onClick={() => setShowAssignedCourses(!showAssignedCourses)}
        />
      )}
    </Container>
  );
};
