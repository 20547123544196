import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserType } from "../../assets/interfaces";
import axios from "axios";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (authToken: string) => {
    const response = await axios.get<UserType[]>(
      `${process.env.REACT_APP_API_URL}/accounts`,
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );
    return response.data;
  }
);
