import axios from "axios";
import { useAppSelector } from "../../hooks";

const token = sessionStorage.getItem("token");

export class CoursesService {
  constructor() {}

  async finishCourse({
    userId,
    courseId,
  }: {
    userId: string | null;
    courseId: string | null;
  }): Promise<boolean> {
    const finishData = {
      userId,
      courseId,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/courses/finish-course`,
        finishData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      return true;
    } catch {
      return false;
    }
  }

  async newAccess({
    userId,
    courseId,
    startDate,
    endDate,
  }: {
    userId: string | null;
    courseId: string | null;
    startDate: Date | null;
    endDate: Date | null;
  }): Promise<boolean> {
    const contactData = {
      userId,
      courseId,
      startDate,
      endDate,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/courses/new-access`,
        contactData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      return true;
    } catch {
      return false;
    }
  }

  async checkCertificates({ userId }: { userId: string | null }): Promise<any> {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/courses/check-certificates`,
        {
          headers: {
            "auth-token": token,
          },
          params: { userId },
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async checkAccesses({ userId }: { userId: string | null }): Promise<any> {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/courses/accesses`,
        {
          headers: {
            "auth-token": token,
          },
          params: { userId },
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
}
