import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { NavigationUrl } from "../assets/interfaces";
import "../assets/styles/styles.css";
import { SignOut } from "../stores";
import { useAppDispatch } from "../hooks";

interface MobileNavigation {
  headerHeight: number;
  isOpen: boolean;
  urls: NavigationUrl[];
}

export const MobileNavigation = ({
  headerHeight,
  isOpen,
  urls,
}: MobileNavigation) => {
  const dispatch = useAppDispatch();
  const handleSignOut = () => {
    dispatch(SignOut());
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: headerHeight + "px",
        transform: "translateY(-13px)",
        left: isOpen ? "0vw" : "-100vw",
        height: "auto",
        width: "100vw",
        backgroundColor: "#f9f9f9",
        transition: "300ms",
      }}
    >
      {urls.map((page, index) =>
        index === urls.length - 1 ? (
          <span
            onClick={handleSignOut}
            className="hover-red-color"
            key={index}
            style={{
              marginBottom: "5vh",
              marginTop: "5vh",
              paddingLeft: "0",
              color: "#000",
              textTransform: "uppercase",
              fontWeight: "700",
              transition: "300ms",
              cursor: "pointer",
            }}
          >
            Wyloguj
          </span>
        ) : (
          <Link
            style={{
              color: "#000",
              marginTop: "5vh",
              marginBottom: "0",
              textTransform: "uppercase",
              fontWeight: "700",
              transition: "300ms",
              cursor: "pointer",
            }}
            underline="none"
            className="hover-red-color"
            key={index}
            href={page.path}
          >
            {page.urlName}
          </Link>
        )
      )}
    </Box>
  );
};
