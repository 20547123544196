import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { plPL } from "@mui/material/locale";

// A custom theme for this app
export const theme = createTheme(plPL, {
  palette: {
    primary: {
      main: "#b22a2a",
    },
    text: {
      primary: "#000",
    },
    secondary: {
      main: "#cc3131",
    },
    error: {
      main: red.A400,
    },
  },
});
