export const navigationLinks = [
  {
    path: "/panel",
    urlName: "Panel",
    specialAccess: false,
  },
  {
    path: "/administrator",
    urlName: "Zarządzaj",
    specialAccess: true,
  },
  {
    path: "/kontakt",
    urlName: "Kontakt",
    specialAccess: false,
  },
  {
    path: "/",
    urlName: "Wyloguj",
    specialAccess: false,
  },
];
