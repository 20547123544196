import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUsers } from "./thunks";
import { UsersStoreType, UserType } from "../../assets/interfaces";

const initialState: UsersStoreType = {
  loading: false,
  users: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getUsers.fulfilled,
        (state, action: PayloadAction<UserType[]>) => {
          state.loading = false;
          state.users = action.payload;
        }
      )
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
        state.users = initialState.users;
      });
  },
});

export default usersSlice.reducer;
