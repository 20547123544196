import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme";
import { Copyright } from "../components/Copyright";
import "../assets/styles/styles.css";
import "../assets/styles/animations.css";
import { AlertPosition, AlertType, CopyrightType } from "../assets/enums";
import { HeadingLogo } from "../components";
import ActionAlerts from "../ui/Alerts";
import { useAppDispatch } from "../hooks";
import { UpdatePassword } from "../stores";
import { passwordValidate } from "../assets/validators";

export const UpdatePasswordPage = () => {
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  )
    return <Navigate to="/panel" />;

  const { resetToken } = useParams();
  if (!resetToken) return <Navigate to="/" />;

  const [displayErrorAlert, setIsDisplayAlert] = React.useState<boolean>(false);
  const [alertTitle, setAlertTitle] = React.useState<string>("");
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  const dispatch = useAppDispatch();

  const showAlert = () => {
    setIsDisplayAlert(true);
    setTimeout(() => {
      setIsDisplayAlert(false);
    }, 5000);
  };

  const closeAlert = () => {
    setIsDisplayAlert(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      password: data.get("password") as string,
      confirmPassword: data.get("confirm-password") as String,
    };

    if (formData.password !== formData.confirmPassword) {
      setAlertTitle("Niezgodne hasła");
      setAlertMessage("Twoje hasła nie są takie same.");
      return showAlert();
    }

    if (!passwordValidate(formData.password)) {
      setAlertTitle("Niepoprawne hasło");
      setAlertMessage(
        "Zadbaj o swoje bezpieczeństwo. Hasło powinno zawierać duże i małe litery oraz znak specjalny, a także nie być krótsze niż 8 znaków"
      );
      return showAlert();
    }
    try {
      const payload = await dispatch(
        UpdatePassword({ password: formData.password, id: resetToken })
      );

      return (window.location.href = "/");
    } catch (error: any) {
      showAlert();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {displayErrorAlert && (
        <ActionAlerts
          alertDisplayStatus={true}
          alertPosition={AlertPosition.Fixed}
          alertType={AlertType.Error}
          alertTitle={alertTitle}
          alertText={alertMessage}
          setStateOfAlert={closeAlert}
        />
      )}
      <Container className="fadeIn" component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "100vh",
            justifyContent: "center",
          }}
        >
          <HeadingLogo />

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Wpisz swoje nowe hasło"
              name="password"
              autoComplete="password"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirm-password"
              label="Wpisz ponownie swoje hasło"
              type="password"
              id="confirm-password"
              autoComplete="confirm-password"
            />
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mt: 3, mb: 3 }}
                  className="hover-button-color"
                >
                  Resetuj hasło
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                Nie posiadasz konta?
                <Link href="/rejestracja" variant="body2">
                  {" Załóż je!"}
                </Link>
              </Grid>
              <Grid item>
                Posiadasz konto?
                <Link href="/" variant="body2">
                  {" Zaloguj się!"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright type={CopyrightType.Form} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};
