import * as React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { icons } from "../assets/icons";

const Search = styled("div")(({ theme }) => ({
  margin: "0 auto !important",
  position: "relative",
  bottom: "15px",
  borderRadius: "50px",
  padding: "10px 20px",
  transition: "300ms",
  marginRight: theme.spacing(2),
  border: "2px solid #ddd",
  marginLeft: 0,
  maxWidth: "320px",
  width: "90%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(() => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  paddingBottom: "20px ",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export interface SearchBarProps {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
}

export const SearchBar = ({ handleSearch, searchQuery }: SearchBarProps) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  return (
    <Search className="fadeIn">
      <SearchIconWrapper style={{ display: "flex", alignItems: "center" }}>
        <img
          src={icons.magnifer}
          style={{
            width: "30px",
          }}
          alt="Szukaj"
        />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Szukaj użytkownika..."
        inputProps={{ "aria-label": "search" }}
        onChange={handleSearch}
        onKeyPress={handleKeyPress}
        value={searchQuery}
      />
    </Search>
  );
};
