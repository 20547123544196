import * as React from "react";
import { Container } from "@mui/material";
import { Courses } from "../assets/interfaces";
import { CourseItem } from "../ui";
import "../assets/styles/courses.css";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getCourses, getMe } from "../stores";

interface Props {
  showAssignedCourses: boolean;
  courses: Courses[];
  onCourseItemClick: (id: string) => void;
}

export const CoursesList = ({
  courses,
  showAssignedCourses,
  onCourseItemClick,
}: Props) => {
  const handleCourseItemClick = (id: string) => {
    onCourseItemClick(id);
  };
  const dispatch = useAppDispatch();
  const token = sessionStorage.getItem("token")!;

  const { user } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(getMe(token));
    const getCoursesAsync = async () => {
      dispatch(
        getCourses({
          userId: user.id!,
          authToken: token,
        })
      );
    };
    getCoursesAsync();
  }, [dispatch, user.id, token]);

  const coursesWithAccess = courses.filter(
    (course) => course.accessStatus === true
  );

  return (
    <Container className="course-container">
      {!showAssignedCourses ? (
        courses.length < 1 ? (
          <p className="fadeIn">
            Niestety nie znaleziono żadnych dostępnych kursów...
          </p>
        ) : (
          <div className="course-container-list">
            {courses.map((course, index) => (
              <CourseItem
                {...course}
                key={index}
                onCourseItemClick={handleCourseItemClick}
              />
            ))}
          </div>
        )
      ) : (
        <div>
          {coursesWithAccess.length === 0 ? (
            <p className="fadeIn">
              Niestety nie posiadasz dostępów do żadnych kursów...
            </p>
          ) : (
            coursesWithAccess.map((course, index) => (
              <CourseItem
                {...course}
                key={index}
                onCourseItemClick={handleCourseItemClick}
              />
            ))
          )}
        </div>
      )}
    </Container>
  );
};
