import { LegacyTemplateType } from "../interfaces";

export const statute: LegacyTemplateType = {
  friendlyUrl: "regulamin-serwisu",
  label: `REGULAMIN SERWISU WWW.ADMINISTRACJA.EDU.PL`,
  htmlContent: `
  <p  align="center"><strong><em>REGULAMIN SERWISU <a style="color: #b22a2a" href="http://www.archiwizacja.edu.pl/">WWW.ADMINISTRACJA.EDU.PL</a><br>obowiązuje od dnia 21.02.2023 r.</em></strong></p>
<br />
<p  align="center"><strong><em>&sect; 1</em></strong></p>
<p  align="center"><strong><em>Definicje</em></strong></p>
<p >Definicje użyte w regulaminie:</p>
<ol>
<li>konto użytkownika &ndash; imienne konto uczestnika szkolenia, założone przez użytkownika w&nbsp;ramach formularza rejestracyjnego,</li>
<li>kurs &ndash; materiały w szczeg&oacute;lności w wersji video, ale także inne materiały mające za zadanie przekazać wiedzę w obrębie tematu kursu,</li>
<li>ODOLegis &ndash; właściciel serwisu tj. ODO Legis sp&oacute;łka z ograniczoną odpowiedzialnością z&nbsp;siedzibą w Żorach, wpisaną do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego, prowadzonego przez Sąd Rejonowy w Gliwicach, X Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0000916931, kapitał zakładowy 5.000 zł, adres: ul. Bankowa 4a/13, 44-240 Żory, NIP: 0001122333, reprezentowana zgodnie z informacją odpowiadającą odpisowi aktualnemu z rejestru przedsiębiorc&oacute;w KRS,</li>
<li>serwis/platforma szkoleniowa &ndash; serwis szkoleniowy, upubliczniony na stronie <a style="color: #b22a2a" href="http://www.administracja.edu.pl/">www.administracja.edu.pl</a></li>
<li>użytkownik &ndash; osoba fizyczna, kt&oacute;ra posiada konto w ramach serwisu szkoleniowego,</li>
</ol>
<br />
<p  align="center"><strong><em>&sect; 2</em></strong></p>
<p  align="center"><strong><em>Rejestracja użytkownik&oacute;w</em></strong></p>
<ol>
<li>Korzystanie ze szkoleń on-line jest możliwe tylko dla zarejestrowanych użytkownik&oacute;w, oznacza to tyle, że aby korzystać ze szkoleń należy założyć indywidualne konto użytkownika na platformie <a style="color: #b22a2a" href="http://www.administracja.edu.pl/">www.administracja.edu.pl</a>.</li>
<li>Założenie wyżej wymienionego konta jest możliwe po kliknięciu w podlinkowany tekst tj. &bdquo;Nie posiadasz konta? Zał&oacute;ż je!&rdquo;.</li>
<li>W formularzu rejestracyjnym należy uzupełnić swoje dane, a dokładnie: imię, nazwisko, adres e-mail, dokładną nazwę pracodawcy/firm/jednostki budżetowej, kt&oacute;ra skierowała Cię na odpowiedni kurs. Dodatkowo podczas tworzenia konta należy ustalić hasło niezbędne do p&oacute;źniejszego logowania do konta użytkownika.</li>
<li>Przed utworzeniem konta, należy zaakceptować treść zgody na przetwarzanie danych osobowych, politykę prywatności oraz niniejszy regulamin. Bez akceptacji wcześniej wymienionych regulacji nie jest możliwe założenie konta użytkownika. Więcej informacji w&nbsp;sprawie przetwarzania Twoich danych osobowych znajdziesz w polityce prywatności.</li>
<li>Konto użytkownika jest aktywne przez okres 5 lat licząc od dnia dodania kursu do imiennego konta użytkownika. Po tym terminie zostanie usunięte z platformy szkoleniowej.</li>
</ol>
<br />
<p  align="center"><strong><em>&sect; 3</em></strong></p>
<p  align="center"><strong><em>Dostęp do szkoleń/kurs&oacute;w</em></strong></p>
<ol>
<li>W celu dodania szkolenia do Twojego kursu niezbędne jest zam&oacute;wienie odpowiedniego szkolenia/kursu.</li>
<li>Zam&oacute;wienie można złożyć telefonicznie pod numerem (032) 420 73 13 lub poprzez e-mail: <a style="color: #b22a2a" href="mailto:biuro@odolegis.pl">biuro@odolegis.pl</a>, lub wysyłając prośbę w ramach konta użytkownika o dostęp - kontaktując się tym samym z naszym działem sprzedaży.</li>
<li>Zgodnie z zam&oacute;wieniem i fakturą kurs dodawany jest do odpowiedniego konta użytkownika w terminie 4 dni roboczych, licząc od dnia po otrzymaniu zam&oacute;wienia przez dział sprzedaży.</li>
</ol>
<br />
<p  align="center"><strong><em>&sect; 4</em></strong></p>
<p  align="center"><strong><em>Korzystanie ze szkoleń/kurs&oacute;w</em></strong></p>
<ol>
<li>Użytkownik ma dostęp do treści kursu przez 90 dni kalendarzowych, licząc od dnia następnego po dacie dodania kursu do konta.</li>
<li>Materiały kursowe nie posiadają ograniczenia w liczbie wyświetleń przez użytkownika w okresie dostępu do kursu, o kt&oacute;rym mowa w ust.1.</li>
<li>Użytkownik ma prawo do pobierania oraz drukowania całych stron lub fragment&oacute;w portalu pod warunkiem nienaruszania praw autorskich oraz praw o rejestracji znak&oacute;w należących do ODO Legis sp. z o.o. Żadna część serwisu nie może być w całości lub części kopiowana w&nbsp;celach komercyjnych, transmitowana elektronicznie lub w inny spos&oacute;b modyfikowana, linkowana lub wykorzystana bez uprzedniej zgody.</li>
<li>Po ukończeniu kursu użytkownik może pobrać certyfikat potwierdzający udział w kursie. W&nbsp;celu uzyskania ceryfikatu z podpisem tradycyjnym, użytkownik musi zgłosić taki wniosek poprzez wysłanie wiadomości e-mail na adres <a style="color: #b22a2a" href="mailto:biuro@odolegis.pl">biuro@odolegis.pl</a> lub korzystając z formularza kontaktowego (zakładka kontakt).</li>
<li>Zakończone kursy (dla kt&oacute;rych minął 90dniowy okres ważności), widnieją na koncie użytkownika jako kursy zakończone.</li>
</ol>
<br />
<p  align="center"><strong><em>&sect; 5</em></strong></p>
<p  align="center"><strong><em>Postanowienia końcowe</em></strong></p>
<ol>
<li>Niniejszy regulamin obowiązuje od dnia 21.02.2023 r. do odwołania.</li>
<li>ODOLegis zastrzega sobie zmianę regulaminu w każdym momencie, tym samym informując o&nbsp;jego zmianie aktywnych użytkownik&oacute;w serwisu.</li>
<li>Aktualna wersja regulaminu serwisu dostępna jest na stronie <a style="color: #b22a2a" href="http://www.administracja.edu.pl/">www.administracja.edu.pl</a>.</li>
</ol>
        `,
};
